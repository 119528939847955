@import url(https://fonts.leaseplancdn.com/v1/newco/fonts.css);
body {
  font-weight: 400;
  font-weight: var(--vel-typography-text-300-font_weight);
  font-size: 18px;
  font-size: var(--vel-typography-text-300-font_size);
  line-height: 1.3333333333333333;
  line-height: var(--vel-typography-text-300-line_height);
  font-family: 'Source Sans 3', sans-serif;
  font-family: var(--vel-typography-text-300-font_family);
  letter-spacing: normal;
  letter-spacing: var(--vel-typography-text-300-letter_spacing);
  color: rgb(15, 53, 73);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
:root {
  --vel-zindex-dropdown: 10;
  --vel-zindex-top_navigation: 1000;
  --vel-zindex-tooltip: 1100;
  --vel-zindex-drawer: 1200;
  --vel-zindex-dropdown-inside-drawer: 1210;
  --vel-zindex-modal: 1300;
  --vel-zindex-dropdown-inside-modal: 1310;
  --vel-zindex-toaster: 1400;
  --vel-zindex-grid_util: 10000;
}
:root {
  --vel-breakpoint-xs: 0px;
  --vel-breakpoint-sm: 480px;
  --vel-breakpoint-md: 740px;
  --vel-breakpoint-lg: 960px;
  --vel-breakpoint-xl: 1278px;
  --vel-breakpoint-xxl: 1600px;
}
:root {
  --vel-fixed-spacing-01: 2px;
  --vel-fixed-spacing-02: 4px;
  --vel-fixed-spacing-03: 8px;
  --vel-fixed-spacing-04: 12px;
  --vel-fixed-spacing-05: 16px;
  --vel-fixed-spacing-06: 20px;
  --vel-fixed-spacing-07: 24px;
  --vel-fixed-spacing-08: 32px;
  --vel-fixed-spacing-09: 40px;
  --vel-layout-spacing-01: 4px;
  --vel-layout-spacing-02: 8px;
  --vel-layout-spacing-03: 12px;
  --vel-layout-spacing-04: 16px;
  --vel-layout-spacing-05: 24px;
  --vel-layout-spacing-06: 32px;
  --vel-layout-spacing-07: 48px;
  --vel-layout-spacing-08: 64px;
}
@media only screen and (min-width: 740px) {
  :root {
    --vel-layout-spacing-01: 4px;
    --vel-layout-spacing-02: 8px;
    --vel-layout-spacing-03: 16px;
    --vel-layout-spacing-04: 24px;
    --vel-layout-spacing-05: 32px;
    --vel-layout-spacing-06: 48px;
    --vel-layout-spacing-07: 64px;
    --vel-layout-spacing-08: 96px;
  }
}
.vel-margin-top-01.vel-margin-top-01 {
  -webkit-margin-before: 2px;
          margin-block-start: 2px;
  -webkit-margin-before: var(--vel-fixed-spacing-01);
          margin-block-start: var(--vel-fixed-spacing-01);
}
.vel-margin-bottom-01.vel-margin-bottom-01 {
  -webkit-margin-after: 2px;
          margin-block-end: 2px;
  -webkit-margin-after: var(--vel-fixed-spacing-01);
          margin-block-end: var(--vel-fixed-spacing-01);
}
.vel-margin-y-01.vel-margin-y-01 {
  margin-block: 2px;
  margin-block: var(--vel-fixed-spacing-01);
}
.vel-margin-start-01.vel-margin-start-01 {
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-start: var(--vel-fixed-spacing-01);
          margin-inline-start: var(--vel-fixed-spacing-01);
}
.vel-margin-end-01.vel-margin-end-01 {
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
  -webkit-margin-end: var(--vel-fixed-spacing-01);
          margin-inline-end: var(--vel-fixed-spacing-01);
}
.vel-margin-x-01.vel-margin-x-01 {
  margin-inline: 2px;
  margin-inline: var(--vel-fixed-spacing-01);
}
.vel-margin-around-01.vel-margin-around-01 {
  margin: 2px;
  margin: var(--vel-fixed-spacing-01);
}
.vel-margin-top-02.vel-margin-top-02 {
  -webkit-margin-before: 4px;
          margin-block-start: 4px;
  -webkit-margin-before: var(--vel-fixed-spacing-02);
          margin-block-start: var(--vel-fixed-spacing-02);
}
.vel-margin-bottom-02.vel-margin-bottom-02 {
  -webkit-margin-after: 4px;
          margin-block-end: 4px;
  -webkit-margin-after: var(--vel-fixed-spacing-02);
          margin-block-end: var(--vel-fixed-spacing-02);
}
.vel-margin-y-02.vel-margin-y-02 {
  margin-block: 4px;
  margin-block: var(--vel-fixed-spacing-02);
}
.vel-margin-start-02.vel-margin-start-02 {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-start: var(--vel-fixed-spacing-02);
          margin-inline-start: var(--vel-fixed-spacing-02);
}
.vel-margin-end-02.vel-margin-end-02 {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-margin-end: var(--vel-fixed-spacing-02);
          margin-inline-end: var(--vel-fixed-spacing-02);
}
.vel-margin-x-02.vel-margin-x-02 {
  margin-inline: 4px;
  margin-inline: var(--vel-fixed-spacing-02);
}
.vel-margin-around-02.vel-margin-around-02 {
  margin: 4px;
  margin: var(--vel-fixed-spacing-02);
}
.vel-margin-top-03.vel-margin-top-03 {
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
  -webkit-margin-before: var(--vel-fixed-spacing-03);
          margin-block-start: var(--vel-fixed-spacing-03);
}
.vel-margin-bottom-03.vel-margin-bottom-03 {
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
  -webkit-margin-after: var(--vel-fixed-spacing-03);
          margin-block-end: var(--vel-fixed-spacing-03);
}
.vel-margin-y-03.vel-margin-y-03 {
  margin-block: 8px;
  margin-block: var(--vel-fixed-spacing-03);
}
.vel-margin-start-03.vel-margin-start-03 {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-start: var(--vel-fixed-spacing-03);
          margin-inline-start: var(--vel-fixed-spacing-03);
}
.vel-margin-end-03.vel-margin-end-03 {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
  -webkit-margin-end: var(--vel-fixed-spacing-03);
          margin-inline-end: var(--vel-fixed-spacing-03);
}
.vel-margin-x-03.vel-margin-x-03 {
  margin-inline: 8px;
  margin-inline: var(--vel-fixed-spacing-03);
}
.vel-margin-around-03.vel-margin-around-03 {
  margin: 8px;
  margin: var(--vel-fixed-spacing-03);
}
.vel-margin-top-04.vel-margin-top-04 {
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-before: var(--vel-fixed-spacing-04);
          margin-block-start: var(--vel-fixed-spacing-04);
}
.vel-margin-bottom-04.vel-margin-bottom-04 {
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
  -webkit-margin-after: var(--vel-fixed-spacing-04);
          margin-block-end: var(--vel-fixed-spacing-04);
}
.vel-margin-y-04.vel-margin-y-04 {
  margin-block: 12px;
  margin-block: var(--vel-fixed-spacing-04);
}
.vel-margin-start-04.vel-margin-start-04 {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-start: var(--vel-fixed-spacing-04);
          margin-inline-start: var(--vel-fixed-spacing-04);
}
.vel-margin-end-04.vel-margin-end-04 {
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
  -webkit-margin-end: var(--vel-fixed-spacing-04);
          margin-inline-end: var(--vel-fixed-spacing-04);
}
.vel-margin-x-04.vel-margin-x-04 {
  margin-inline: 12px;
  margin-inline: var(--vel-fixed-spacing-04);
}
.vel-margin-around-04.vel-margin-around-04 {
  margin: 12px;
  margin: var(--vel-fixed-spacing-04);
}
.vel-margin-top-05.vel-margin-top-05 {
  -webkit-margin-before: 16px;
          margin-block-start: 16px;
  -webkit-margin-before: var(--vel-fixed-spacing-05);
          margin-block-start: var(--vel-fixed-spacing-05);
}
.vel-margin-bottom-05.vel-margin-bottom-05 {
  -webkit-margin-after: 16px;
          margin-block-end: 16px;
  -webkit-margin-after: var(--vel-fixed-spacing-05);
          margin-block-end: var(--vel-fixed-spacing-05);
}
.vel-margin-y-05.vel-margin-y-05 {
  margin-block: 16px;
  margin-block: var(--vel-fixed-spacing-05);
}
.vel-margin-start-05.vel-margin-start-05 {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-start: var(--vel-fixed-spacing-05);
          margin-inline-start: var(--vel-fixed-spacing-05);
}
.vel-margin-end-05.vel-margin-end-05 {
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
  -webkit-margin-end: var(--vel-fixed-spacing-05);
          margin-inline-end: var(--vel-fixed-spacing-05);
}
.vel-margin-x-05.vel-margin-x-05 {
  margin-inline: 16px;
  margin-inline: var(--vel-fixed-spacing-05);
}
.vel-margin-around-05.vel-margin-around-05 {
  margin: 16px;
  margin: var(--vel-fixed-spacing-05);
}
.vel-margin-top-06.vel-margin-top-06 {
  -webkit-margin-before: 20px;
          margin-block-start: 20px;
  -webkit-margin-before: var(--vel-fixed-spacing-06);
          margin-block-start: var(--vel-fixed-spacing-06);
}
.vel-margin-bottom-06.vel-margin-bottom-06 {
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
  -webkit-margin-after: var(--vel-fixed-spacing-06);
          margin-block-end: var(--vel-fixed-spacing-06);
}
.vel-margin-y-06.vel-margin-y-06 {
  margin-block: 20px;
  margin-block: var(--vel-fixed-spacing-06);
}
.vel-margin-start-06.vel-margin-start-06 {
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
  -webkit-margin-start: var(--vel-fixed-spacing-06);
          margin-inline-start: var(--vel-fixed-spacing-06);
}
.vel-margin-end-06.vel-margin-end-06 {
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
  -webkit-margin-end: var(--vel-fixed-spacing-06);
          margin-inline-end: var(--vel-fixed-spacing-06);
}
.vel-margin-x-06.vel-margin-x-06 {
  margin-inline: 20px;
  margin-inline: var(--vel-fixed-spacing-06);
}
.vel-margin-around-06.vel-margin-around-06 {
  margin: 20px;
  margin: var(--vel-fixed-spacing-06);
}
.vel-margin-top-07.vel-margin-top-07 {
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  -webkit-margin-before: var(--vel-fixed-spacing-07);
          margin-block-start: var(--vel-fixed-spacing-07);
}
.vel-margin-bottom-07.vel-margin-bottom-07 {
  -webkit-margin-after: 24px;
          margin-block-end: 24px;
  -webkit-margin-after: var(--vel-fixed-spacing-07);
          margin-block-end: var(--vel-fixed-spacing-07);
}
.vel-margin-y-07.vel-margin-y-07 {
  margin-block: 24px;
  margin-block: var(--vel-fixed-spacing-07);
}
.vel-margin-start-07.vel-margin-start-07 {
  -webkit-margin-start: 24px;
          margin-inline-start: 24px;
  -webkit-margin-start: var(--vel-fixed-spacing-07);
          margin-inline-start: var(--vel-fixed-spacing-07);
}
.vel-margin-end-07.vel-margin-end-07 {
  -webkit-margin-end: 24px;
          margin-inline-end: 24px;
  -webkit-margin-end: var(--vel-fixed-spacing-07);
          margin-inline-end: var(--vel-fixed-spacing-07);
}
.vel-margin-x-07.vel-margin-x-07 {
  margin-inline: 24px;
  margin-inline: var(--vel-fixed-spacing-07);
}
.vel-margin-around-07.vel-margin-around-07 {
  margin: 24px;
  margin: var(--vel-fixed-spacing-07);
}
.vel-margin-top-08.vel-margin-top-08 {
  -webkit-margin-before: 32px;
          margin-block-start: 32px;
  -webkit-margin-before: var(--vel-fixed-spacing-08);
          margin-block-start: var(--vel-fixed-spacing-08);
}
.vel-margin-bottom-08.vel-margin-bottom-08 {
  -webkit-margin-after: 32px;
          margin-block-end: 32px;
  -webkit-margin-after: var(--vel-fixed-spacing-08);
          margin-block-end: var(--vel-fixed-spacing-08);
}
.vel-margin-y-08.vel-margin-y-08 {
  margin-block: 32px;
  margin-block: var(--vel-fixed-spacing-08);
}
.vel-margin-start-08.vel-margin-start-08 {
  -webkit-margin-start: 32px;
          margin-inline-start: 32px;
  -webkit-margin-start: var(--vel-fixed-spacing-08);
          margin-inline-start: var(--vel-fixed-spacing-08);
}
.vel-margin-end-08.vel-margin-end-08 {
  -webkit-margin-end: 32px;
          margin-inline-end: 32px;
  -webkit-margin-end: var(--vel-fixed-spacing-08);
          margin-inline-end: var(--vel-fixed-spacing-08);
}
.vel-margin-x-08.vel-margin-x-08 {
  margin-inline: 32px;
  margin-inline: var(--vel-fixed-spacing-08);
}
.vel-margin-around-08.vel-margin-around-08 {
  margin: 32px;
  margin: var(--vel-fixed-spacing-08);
}
.vel-margin-top-09.vel-margin-top-09 {
  -webkit-margin-before: 40px;
          margin-block-start: 40px;
  -webkit-margin-before: var(--vel-fixed-spacing-09);
          margin-block-start: var(--vel-fixed-spacing-09);
}
.vel-margin-bottom-09.vel-margin-bottom-09 {
  -webkit-margin-after: 40px;
          margin-block-end: 40px;
  -webkit-margin-after: var(--vel-fixed-spacing-09);
          margin-block-end: var(--vel-fixed-spacing-09);
}
.vel-margin-y-09.vel-margin-y-09 {
  margin-block: 40px;
  margin-block: var(--vel-fixed-spacing-09);
}
.vel-margin-start-09.vel-margin-start-09 {
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  -webkit-margin-start: var(--vel-fixed-spacing-09);
          margin-inline-start: var(--vel-fixed-spacing-09);
}
.vel-margin-end-09.vel-margin-end-09 {
  -webkit-margin-end: 40px;
          margin-inline-end: 40px;
  -webkit-margin-end: var(--vel-fixed-spacing-09);
          margin-inline-end: var(--vel-fixed-spacing-09);
}
.vel-margin-x-09.vel-margin-x-09 {
  margin-inline: 40px;
  margin-inline: var(--vel-fixed-spacing-09);
}
.vel-margin-around-09.vel-margin-around-09 {
  margin: 40px;
  margin: var(--vel-fixed-spacing-09);
}
.vel-layout-margin-top-01.vel-layout-margin-top-01 {
  -webkit-margin-before: 4px;
          margin-block-start: 4px;
  -webkit-margin-before: var(--vel-layout-spacing-01);
          margin-block-start: var(--vel-layout-spacing-01);
}
.vel-layout-margin-bottom-01.vel-layout-margin-bottom-01 {
  -webkit-margin-after: 4px;
          margin-block-end: 4px;
  -webkit-margin-after: var(--vel-layout-spacing-01);
          margin-block-end: var(--vel-layout-spacing-01);
}
.vel-layout-margin-y-01.vel-layout-margin-y-01 {
  margin-block: 4px;
  margin-block: var(--vel-layout-spacing-01);
}
.vel-layout-margin-start-01.vel-layout-margin-start-01 {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-start: var(--vel-layout-spacing-01);
          margin-inline-start: var(--vel-layout-spacing-01);
}
.vel-layout-margin-end-01.vel-layout-margin-end-01 {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-margin-end: var(--vel-layout-spacing-01);
          margin-inline-end: var(--vel-layout-spacing-01);
}
.vel-layout-margin-x-01.vel-layout-margin-x-01 {
  margin-inline: 4px;
  margin-inline: var(--vel-layout-spacing-01);
}
.vel-layout-margin-around-01.vel-layout-margin-around-01 {
  margin: 4px;
  margin: var(--vel-layout-spacing-01);
}
.vel-layout-margin-top-02.vel-layout-margin-top-02 {
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
  -webkit-margin-before: var(--vel-layout-spacing-02);
          margin-block-start: var(--vel-layout-spacing-02);
}
.vel-layout-margin-bottom-02.vel-layout-margin-bottom-02 {
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
  -webkit-margin-after: var(--vel-layout-spacing-02);
          margin-block-end: var(--vel-layout-spacing-02);
}
.vel-layout-margin-y-02.vel-layout-margin-y-02 {
  margin-block: 8px;
  margin-block: var(--vel-layout-spacing-02);
}
.vel-layout-margin-start-02.vel-layout-margin-start-02 {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-start: var(--vel-layout-spacing-02);
          margin-inline-start: var(--vel-layout-spacing-02);
}
.vel-layout-margin-end-02.vel-layout-margin-end-02 {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
  -webkit-margin-end: var(--vel-layout-spacing-02);
          margin-inline-end: var(--vel-layout-spacing-02);
}
.vel-layout-margin-x-02.vel-layout-margin-x-02 {
  margin-inline: 8px;
  margin-inline: var(--vel-layout-spacing-02);
}
.vel-layout-margin-around-02.vel-layout-margin-around-02 {
  margin: 8px;
  margin: var(--vel-layout-spacing-02);
}
.vel-layout-margin-top-03.vel-layout-margin-top-03 {
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-before: var(--vel-layout-spacing-03);
          margin-block-start: var(--vel-layout-spacing-03);
}
.vel-layout-margin-bottom-03.vel-layout-margin-bottom-03 {
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
  -webkit-margin-after: var(--vel-layout-spacing-03);
          margin-block-end: var(--vel-layout-spacing-03);
}
.vel-layout-margin-y-03.vel-layout-margin-y-03 {
  margin-block: 12px;
  margin-block: var(--vel-layout-spacing-03);
}
.vel-layout-margin-start-03.vel-layout-margin-start-03 {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-start: var(--vel-layout-spacing-03);
          margin-inline-start: var(--vel-layout-spacing-03);
}
.vel-layout-margin-end-03.vel-layout-margin-end-03 {
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
  -webkit-margin-end: var(--vel-layout-spacing-03);
          margin-inline-end: var(--vel-layout-spacing-03);
}
.vel-layout-margin-x-03.vel-layout-margin-x-03 {
  margin-inline: 12px;
  margin-inline: var(--vel-layout-spacing-03);
}
.vel-layout-margin-around-03.vel-layout-margin-around-03 {
  margin: 12px;
  margin: var(--vel-layout-spacing-03);
}
.vel-layout-margin-top-04.vel-layout-margin-top-04 {
  -webkit-margin-before: 16px;
          margin-block-start: 16px;
  -webkit-margin-before: var(--vel-layout-spacing-04);
          margin-block-start: var(--vel-layout-spacing-04);
}
.vel-layout-margin-bottom-04.vel-layout-margin-bottom-04 {
  -webkit-margin-after: 16px;
          margin-block-end: 16px;
  -webkit-margin-after: var(--vel-layout-spacing-04);
          margin-block-end: var(--vel-layout-spacing-04);
}
.vel-layout-margin-y-04.vel-layout-margin-y-04 {
  margin-block: 16px;
  margin-block: var(--vel-layout-spacing-04);
}
.vel-layout-margin-start-04.vel-layout-margin-start-04 {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-start: var(--vel-layout-spacing-04);
          margin-inline-start: var(--vel-layout-spacing-04);
}
.vel-layout-margin-end-04.vel-layout-margin-end-04 {
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
  -webkit-margin-end: var(--vel-layout-spacing-04);
          margin-inline-end: var(--vel-layout-spacing-04);
}
.vel-layout-margin-x-04.vel-layout-margin-x-04 {
  margin-inline: 16px;
  margin-inline: var(--vel-layout-spacing-04);
}
.vel-layout-margin-around-04.vel-layout-margin-around-04 {
  margin: 16px;
  margin: var(--vel-layout-spacing-04);
}
.vel-layout-margin-top-05.vel-layout-margin-top-05 {
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  -webkit-margin-before: var(--vel-layout-spacing-05);
          margin-block-start: var(--vel-layout-spacing-05);
}
.vel-layout-margin-bottom-05.vel-layout-margin-bottom-05 {
  -webkit-margin-after: 24px;
          margin-block-end: 24px;
  -webkit-margin-after: var(--vel-layout-spacing-05);
          margin-block-end: var(--vel-layout-spacing-05);
}
.vel-layout-margin-y-05.vel-layout-margin-y-05 {
  margin-block: 24px;
  margin-block: var(--vel-layout-spacing-05);
}
.vel-layout-margin-start-05.vel-layout-margin-start-05 {
  -webkit-margin-start: 24px;
          margin-inline-start: 24px;
  -webkit-margin-start: var(--vel-layout-spacing-05);
          margin-inline-start: var(--vel-layout-spacing-05);
}
.vel-layout-margin-end-05.vel-layout-margin-end-05 {
  -webkit-margin-end: 24px;
          margin-inline-end: 24px;
  -webkit-margin-end: var(--vel-layout-spacing-05);
          margin-inline-end: var(--vel-layout-spacing-05);
}
.vel-layout-margin-x-05.vel-layout-margin-x-05 {
  margin-inline: 24px;
  margin-inline: var(--vel-layout-spacing-05);
}
.vel-layout-margin-around-05.vel-layout-margin-around-05 {
  margin: 24px;
  margin: var(--vel-layout-spacing-05);
}
.vel-layout-margin-top-06.vel-layout-margin-top-06 {
  -webkit-margin-before: 32px;
          margin-block-start: 32px;
  -webkit-margin-before: var(--vel-layout-spacing-06);
          margin-block-start: var(--vel-layout-spacing-06);
}
.vel-layout-margin-bottom-06.vel-layout-margin-bottom-06 {
  -webkit-margin-after: 32px;
          margin-block-end: 32px;
  -webkit-margin-after: var(--vel-layout-spacing-06);
          margin-block-end: var(--vel-layout-spacing-06);
}
.vel-layout-margin-y-06.vel-layout-margin-y-06 {
  margin-block: 32px;
  margin-block: var(--vel-layout-spacing-06);
}
.vel-layout-margin-start-06.vel-layout-margin-start-06 {
  -webkit-margin-start: 32px;
          margin-inline-start: 32px;
  -webkit-margin-start: var(--vel-layout-spacing-06);
          margin-inline-start: var(--vel-layout-spacing-06);
}
.vel-layout-margin-end-06.vel-layout-margin-end-06 {
  -webkit-margin-end: 32px;
          margin-inline-end: 32px;
  -webkit-margin-end: var(--vel-layout-spacing-06);
          margin-inline-end: var(--vel-layout-spacing-06);
}
.vel-layout-margin-x-06.vel-layout-margin-x-06 {
  margin-inline: 32px;
  margin-inline: var(--vel-layout-spacing-06);
}
.vel-layout-margin-around-06.vel-layout-margin-around-06 {
  margin: 32px;
  margin: var(--vel-layout-spacing-06);
}
.vel-layout-margin-top-07.vel-layout-margin-top-07 {
  -webkit-margin-before: 48px;
          margin-block-start: 48px;
  -webkit-margin-before: var(--vel-layout-spacing-07);
          margin-block-start: var(--vel-layout-spacing-07);
}
.vel-layout-margin-bottom-07.vel-layout-margin-bottom-07 {
  -webkit-margin-after: 48px;
          margin-block-end: 48px;
  -webkit-margin-after: var(--vel-layout-spacing-07);
          margin-block-end: var(--vel-layout-spacing-07);
}
.vel-layout-margin-y-07.vel-layout-margin-y-07 {
  margin-block: 48px;
  margin-block: var(--vel-layout-spacing-07);
}
.vel-layout-margin-start-07.vel-layout-margin-start-07 {
  -webkit-margin-start: 48px;
          margin-inline-start: 48px;
  -webkit-margin-start: var(--vel-layout-spacing-07);
          margin-inline-start: var(--vel-layout-spacing-07);
}
.vel-layout-margin-end-07.vel-layout-margin-end-07 {
  -webkit-margin-end: 48px;
          margin-inline-end: 48px;
  -webkit-margin-end: var(--vel-layout-spacing-07);
          margin-inline-end: var(--vel-layout-spacing-07);
}
.vel-layout-margin-x-07.vel-layout-margin-x-07 {
  margin-inline: 48px;
  margin-inline: var(--vel-layout-spacing-07);
}
.vel-layout-margin-around-07.vel-layout-margin-around-07 {
  margin: 48px;
  margin: var(--vel-layout-spacing-07);
}
.vel-layout-margin-top-08.vel-layout-margin-top-08 {
  -webkit-margin-before: 64px;
          margin-block-start: 64px;
  -webkit-margin-before: var(--vel-layout-spacing-08);
          margin-block-start: var(--vel-layout-spacing-08);
}
.vel-layout-margin-bottom-08.vel-layout-margin-bottom-08 {
  -webkit-margin-after: 64px;
          margin-block-end: 64px;
  -webkit-margin-after: var(--vel-layout-spacing-08);
          margin-block-end: var(--vel-layout-spacing-08);
}
.vel-layout-margin-y-08.vel-layout-margin-y-08 {
  margin-block: 64px;
  margin-block: var(--vel-layout-spacing-08);
}
.vel-layout-margin-start-08.vel-layout-margin-start-08 {
  -webkit-margin-start: 64px;
          margin-inline-start: 64px;
  -webkit-margin-start: var(--vel-layout-spacing-08);
          margin-inline-start: var(--vel-layout-spacing-08);
}
.vel-layout-margin-end-08.vel-layout-margin-end-08 {
  -webkit-margin-end: 64px;
          margin-inline-end: 64px;
  -webkit-margin-end: var(--vel-layout-spacing-08);
          margin-inline-end: var(--vel-layout-spacing-08);
}
.vel-layout-margin-x-08.vel-layout-margin-x-08 {
  margin-inline: 64px;
  margin-inline: var(--vel-layout-spacing-08);
}
.vel-layout-margin-around-08.vel-layout-margin-around-08 {
  margin: 64px;
  margin: var(--vel-layout-spacing-08);
}
:root {
  --vel-color-primary-500-raw: 0, 98, 98;
  --vel-color-primary-400-raw: 0, 130, 130;
  --vel-color-primary-300-raw: 51, 155, 155;
  --vel-color-primary-200-raw: 102, 180, 180;
  --vel-color-primary-100-raw: 153, 205, 205;
  --vel-color-primary-50-raw: 227, 246, 246;
  --vel-color-secondary-500-raw: 7, 37, 52;
  --vel-color-secondary-400-raw: 15, 53, 73;
  --vel-color-secondary-300-raw: 63, 93, 109;
  --vel-color-secondary-200-raw: 111, 134, 146;
  --vel-color-secondary-100-raw: 159, 174, 182;
  --vel-color-secondary-50-raw: 231, 235, 237;
  --vel-color-brand-support1-500-raw: 255, 205, 24;
  --vel-color-brand-support1-400-raw: 255, 213, 64;
  --vel-color-brand-support1-300-raw: 255, 221, 102;
  --vel-color-brand-support1-200-raw: 255, 230, 140;
  --vel-color-brand-support1-100-raw: 255, 238, 179;
  --vel-color-brand-support1-50-raw: 255, 247, 217;
  --vel-color-brand-support2-500-raw: 88, 200, 200;
  --vel-color-brand-support2-400-raw: 115, 210, 210;
  --vel-color-brand-support2-300-raw: 143, 219, 219;
  --vel-color-brand-support2-200-raw: 171, 228, 228;
  --vel-color-brand-support2-100-raw: 199, 237, 237;
  --vel-color-brand-support2-50-raw: 227, 246, 246;
  --vel-color-brand-support3-500-raw: 209, 144, 198;
  --vel-color-brand-support3-400-raw: 225, 168, 215;
  --vel-color-brand-support3-300-raw: 231, 185, 223;
  --vel-color-brand-support3-200-raw: 237, 203, 231;
  --vel-color-brand-support3-100-raw: 243, 220, 239;
  --vel-color-brand-support3-50-raw: 243, 220, 239;
  --vel-color-neutral-900-raw: 0, 0, 0;
  --vel-color-neutral-800-raw: 45, 45, 45;
  --vel-color-neutral-700-raw: 84, 86, 86;
  --vel-color-neutral-600-raw: 118, 120, 120;
  --vel-color-neutral-500-raw: 152, 154, 154;
  --vel-color-neutral-400-raw: 187, 187, 187;
  --vel-color-neutral-300-raw: 221, 221, 221;
  --vel-color-neutral-200-raw: 238, 238, 238;
  --vel-color-neutral-100-raw: 246, 246, 246;
  --vel-color-neutral-white-raw: 255, 255, 255;
  --vel-color-background-main-raw: 255, 255, 255;
  --vel-color-background-alt-raw: 246, 246, 246;
  --vel-color-semantic-error-high-raw: 207, 53, 22;
  --vel-color-semantic-error-low-raw: 254, 244, 244;
  --vel-color-semantic-success-high-raw: 6, 116, 23;
  --vel-color-semantic-success-low-raw: 237, 248, 238;
  --vel-color-semantic-warning-high-raw: 212, 128, 0;
  --vel-color-semantic-warning-low-raw: 255, 249, 237;
  --vel-color-semantic-info-high-raw: 28, 104, 202;
  --vel-color-semantic-info-low-raw: 240, 246, 255;
  --vel-color-misc-focused-raw: 0, 101, 255;
  --vel-color-misc-pressed-raw: 69, 143, 255, 0.25;
  --vel-color-text-headlines-raw: 15, 53, 73;
  --vel-color-text-default-raw: 15, 53, 73;
  --vel-color-text-on_primary-raw: 255, 255, 255;
  --vel-color-text-on_secondary-raw: 255, 255, 255;
  --vel-color-text-on_brand_support1-raw: 15, 53, 73;
  --vel-color-text-on_brand_support2-raw: 255, 255, 255;
  --vel-color-text-on_brand_support3-raw: 255, 255, 255;
  --vel-color-primary-500: rgb(var(--vel-color-primary-500-raw));
  --vel-color-primary-400: rgb(var(--vel-color-primary-400-raw));
  --vel-color-primary-300: rgb(var(--vel-color-primary-300-raw));
  --vel-color-primary-200: rgb(var(--vel-color-primary-200-raw));
  --vel-color-primary-100: rgb(var(--vel-color-primary-100-raw));
  --vel-color-primary-50: rgb(var(--vel-color-primary-50-raw));
  --vel-color-secondary-500: rgb(var(--vel-color-secondary-500-raw));
  --vel-color-secondary-400: rgb(var(--vel-color-secondary-400-raw));
  --vel-color-secondary-300: rgb(var(--vel-color-secondary-300-raw));
  --vel-color-secondary-200: rgb(var(--vel-color-secondary-200-raw));
  --vel-color-secondary-100: rgb(var(--vel-color-secondary-100-raw));
  --vel-color-secondary-50: rgb(var(--vel-color-secondary-50-raw));
  --vel-color-brand-support1-500: rgb(var(--vel-color-brand-support1-500-raw));
  --vel-color-brand-support1-400: rgb(var(--vel-color-brand-support1-400-raw));
  --vel-color-brand-support1-300: rgb(var(--vel-color-brand-support1-300-raw));
  --vel-color-brand-support1-200: rgb(var(--vel-color-brand-support1-200-raw));
  --vel-color-brand-support1-100: rgb(var(--vel-color-brand-support1-100-raw));
  --vel-color-brand-support1-50: rgb(var(--vel-color-brand-support1-50-raw));
  --vel-color-brand-support2-500: rgb(var(--vel-color-brand-support2-500-raw));
  --vel-color-brand-support2-400: rgb(var(--vel-color-brand-support2-400-raw));
  --vel-color-brand-support2-300: rgb(var(--vel-color-brand-support2-300-raw));
  --vel-color-brand-support2-200: rgb(var(--vel-color-brand-support2-200-raw));
  --vel-color-brand-support2-100: rgb(var(--vel-color-brand-support2-100-raw));
  --vel-color-brand-support2-50: rgb(var(--vel-color-brand-support2-50-raw));
  --vel-color-brand-support3-500: rgb(var(--vel-color-brand-support3-500-raw));
  --vel-color-brand-support3-400: rgb(var(--vel-color-brand-support3-400-raw));
  --vel-color-brand-support3-300: rgb(var(--vel-color-brand-support3-300-raw));
  --vel-color-brand-support3-200: rgb(var(--vel-color-brand-support3-200-raw));
  --vel-color-brand-support3-100: rgb(var(--vel-color-brand-support3-100-raw));
  --vel-color-brand-support3-50: rgb(var(--vel-color-brand-support3-50-raw));
  --vel-color-neutral-900: rgb(var(--vel-color-neutral-900-raw));
  --vel-color-neutral-800: rgb(var(--vel-color-neutral-800-raw));
  --vel-color-neutral-700: rgb(var(--vel-color-neutral-700-raw));
  --vel-color-neutral-600: rgb(var(--vel-color-neutral-600-raw));
  --vel-color-neutral-500: rgb(var(--vel-color-neutral-500-raw));
  --vel-color-neutral-400: rgb(var(--vel-color-neutral-400-raw));
  --vel-color-neutral-300: rgb(var(--vel-color-neutral-300-raw));
  --vel-color-neutral-200: rgb(var(--vel-color-neutral-200-raw));
  --vel-color-neutral-100: rgb(var(--vel-color-neutral-100-raw));
  --vel-color-neutral-white: rgb(var(--vel-color-neutral-white-raw));
  --vel-color-background-main: rgb(var(--vel-color-background-main-raw));
  --vel-color-background-alt: rgb(var(--vel-color-background-alt-raw));
  --vel-color-semantic-error-high: rgb(
    var(--vel-color-semantic-error-high-raw)
  );
  --vel-color-semantic-error-low: rgb(var(--vel-color-semantic-error-low-raw));
  --vel-color-semantic-success-high: rgb(
    var(--vel-color-semantic-success-high-raw)
  );
  --vel-color-semantic-success-low: rgb(
    var(--vel-color-semantic-success-low-raw)
  );
  --vel-color-semantic-warning-high: rgb(
    var(--vel-color-semantic-warning-high-raw)
  );
  --vel-color-semantic-warning-low: rgb(
    var(--vel-color-semantic-warning-low-raw)
  );
  --vel-color-semantic-info-high: rgb(var(--vel-color-semantic-info-high-raw));
  --vel-color-semantic-info-low: rgb(var(--vel-color-semantic-info-low-raw));
  --vel-color-misc-focused: rgb(var(--vel-color-misc-focused-raw));
  --vel-color-misc-pressed: rgba(var(--vel-color-misc-pressed-raw));
  --vel-color-text-headlines: rgb(var(--vel-color-text-headlines-raw));
  --vel-color-text-default: rgb(var(--vel-color-text-default-raw));
  --vel-color-text-on_primary: rgb(var(--vel-color-text-on_primary-raw));
  --vel-color-text-on_secondary: rgb(var(--vel-color-text-on_secondary-raw));
  --vel-color-text-on_brand_support1: rgb(
    var(--vel-color-text-on_brand_support1-raw)
  );
  --vel-color-text-on_brand_support2: rgb(
    var(--vel-color-text-on_brand_support2-raw)
  );
  --vel-color-text-on_brand_support3: rgb(
    var(--vel-color-text-on_brand_support3-raw)
  );
  --vel-color-form-text-placeholder: var(--vel-color-neutral-500);
  --vel-color-form-text-disabled: var(--vel-color-neutral-500);
  --vel-color-form-bg-default: var(--vel-color-neutral-white);
  --vel-color-form-bg-disabled: var(--vel-color-neutral-200);
  --vel-color-form-border-default: var(--vel-color-neutral-500);
  --vel-color-form-border-hover: var(--vel-color-neutral-600);
  --vel-color-form-border-disabled: var(--vel-color-neutral-300);
  --vel-color-dividerColor: var(--vel-color-neutral-300);
}
:root {
  --vel-shape100-border_radius: 0px;
  --vel-shape200-border_radius: 2px;
  --vel-shape300-border_radius: 4px;
  --vel-shape400-border_radius: 12px;
  --vel-shape500-border_radius: 999px;
}
.vel-shape100.vel-shape100 {
  border-radius: 0px;
  border-radius: var(--vel-shape100-border_radius);
}
.vel-shape200.vel-shape200 {
  border-radius: 2px;
  border-radius: var(--vel-shape200-border_radius);
}
.vel-shape300.vel-shape300 {
  border-radius: 4px;
  border-radius: var(--vel-shape300-border_radius);
}
.vel-shape400.vel-shape400 {
  border-radius: 12px;
  border-radius: var(--vel-shape400-border_radius);
}
.vel-shape500.vel-shape500 {
  border-radius: 999px;
  border-radius: var(--vel-shape500-border_radius);
}
:root {
  --vel-elevation100-box_shadow: 0px 2px 2px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation200-box_shadow: 0px 4px 8px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation300-box_shadow: 0px 8px 12px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation400-box_shadow: 0px 12px 16px rgba(9, 30, 66, 0.12),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation500-box_shadow: 0px 16px 32px rgba(9, 30, 66, 0.16),
    0px 0px 1px rgba(9, 30, 66, 0.2);
}
.vel-elevation100.vel-elevation100 {
  box-shadow: 0px 2px 2px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation100-box_shadow);
}
.vel-elevation200.vel-elevation200 {
  box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation200-box_shadow);
}
.vel-elevation300.vel-elevation300 {
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation300-box_shadow);
}
.vel-elevation400.vel-elevation400 {
  box-shadow: 0px 12px 16px rgba(9, 30, 66, 0.12),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation400-box_shadow);
}
.vel-elevation500.vel-elevation500 {
  box-shadow: 0px 16px 32px rgba(9, 30, 66, 0.16),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation500-box_shadow);
}
:root {
  --vel-elevation100-filter: drop-shadow(0px 2px 2px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation200-filter: drop-shadow(0px 4px 8px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation300-filter: drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation400-filter: drop-shadow(0px 12px 16px rgba(9, 30, 66, 0.12))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation500-filter: drop-shadow(0px 16px 32px rgba(9, 30, 66, 0.16))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
}
:root {
  --vel-typography-text-50-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-50-font_weight: 400;
  --vel-typography-text-50-letter_spacing: normal;
  --vel-typography-text-50-font_size: 11px;
  --vel-typography-text-50-line_height: 1.4545454545454546;
  --vel-typography-text-50-bold-font_weight: 700;
  --vel-typography-text-50-loose-line_height: 1.8181818181818181;
  --vel-typography-text-100-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-100-font_weight: 400;
  --vel-typography-text-100-letter_spacing: normal;
  --vel-typography-text-100-font_size: 14px;
  --vel-typography-text-100-line_height: 1.4285714285714286;
  --vel-typography-text-100-bold-font_weight: 700;
  --vel-typography-text-100-loose-line_height: 1.7142857142857142;
  --vel-typography-text-200-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-200-font_weight: 400;
  --vel-typography-text-200-letter_spacing: normal;
  --vel-typography-text-200-font_size: 16px;
  --vel-typography-text-200-line_height: 1.25;
  --vel-typography-text-200-bold-font_weight: 700;
  --vel-typography-text-200-loose-line_height: 1.5;
  --vel-typography-text-300-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-300-font_weight: 400;
  --vel-typography-text-300-letter_spacing: normal;
  --vel-typography-text-300-font_size: 18px;
  --vel-typography-text-300-line_height: 1.3333333333333333;
  --vel-typography-text-300-bold-font_weight: 700;
  --vel-typography-text-300-loose-line_height: 1.5555555555555556;
  --vel-typography-text-400-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-400-font_weight: 400;
  --vel-typography-text-400-letter_spacing: normal;
  --vel-typography-text-400-font_size: 23px;
  --vel-typography-text-400-line_height: 1.2173913043478262;
  --vel-typography-text-400-bold-font_weight: 700;
  --vel-typography-text-400-loose-line_height: 1.565217391304348;
  --vel-typography-text-500-font_family: 'Source Sans 3', sans-serif;
  --vel-typography-text-500-font_weight: 400;
  --vel-typography-text-500-letter_spacing: normal;
  --vel-typography-text-500-font_size: 28px;
  --vel-typography-text-500-line_height: 1.2857142857142858;
  --vel-typography-text-500-bold-font_weight: 700;
  --vel-typography-text-500-loose-line_height: 1.5714285714285714;
  --vel-typography-text-alt-50-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-50-font_weight: 500;
  --vel-typography-text-alt-50-letter_spacing: normal;
  --vel-typography-text-alt-50-font_size: 11px;
  --vel-typography-text-alt-50-line_height: 1.4545454545454546;
  --vel-typography-text-alt-50-bold-font_weight: 600;
  --vel-typography-text-alt-50-loose-line_height: 1.8181818181818181;
  --vel-typography-text-alt-100-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-100-font_weight: 500;
  --vel-typography-text-alt-100-letter_spacing: normal;
  --vel-typography-text-alt-100-font_size: 13px;
  --vel-typography-text-alt-100-line_height: 1.5384615384615385;
  --vel-typography-text-alt-100-bold-font_weight: 600;
  --vel-typography-text-alt-100-loose-line_height: 1.8461538461538463;
  --vel-typography-text-alt-200-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-200-font_weight: 500;
  --vel-typography-text-alt-200-letter_spacing: normal;
  --vel-typography-text-alt-200-font_size: 15px;
  --vel-typography-text-alt-200-line_height: 1.3333333333333333;
  --vel-typography-text-alt-200-bold-font_weight: 600;
  --vel-typography-text-alt-200-loose-line_height: 1.6;
  --vel-typography-text-alt-300-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-300-font_weight: 500;
  --vel-typography-text-alt-300-letter_spacing: normal;
  --vel-typography-text-alt-300-font_size: 17px;
  --vel-typography-text-alt-300-line_height: 1.411764705882353;
  --vel-typography-text-alt-300-bold-font_weight: 600;
  --vel-typography-text-alt-300-loose-line_height: 1.6470588235294117;
  --vel-typography-text-alt-400-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-400-font_weight: 500;
  --vel-typography-text-alt-400-letter_spacing: normal;
  --vel-typography-text-alt-400-font_size: 22px;
  --vel-typography-text-alt-400-line_height: 1.2727272727272727;
  --vel-typography-text-alt-400-bold-font_weight: 600;
  --vel-typography-text-alt-400-loose-line_height: 1.6363636363636365;
  --vel-typography-text-alt-500-font_family: 'Chillax', sans-serif;
  --vel-typography-text-alt-500-font_weight: 500;
  --vel-typography-text-alt-500-letter_spacing: normal;
  --vel-typography-text-alt-500-font_size: 26px;
  --vel-typography-text-alt-500-line_height: 1.3846153846153846;
  --vel-typography-text-alt-500-bold-font_weight: 600;
  --vel-typography-text-alt-500-loose-line_height: 1.6923076923076923;
  --vel-typography-headline-100-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-100-font_weight: 500;
  --vel-typography-headline-100-letter_spacing: normal;
  --vel-typography-headline-100-font_size: 16px;
  --vel-typography-headline-100-line_height: 1.25;
  --vel-typography-headline-200-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-200-font_weight: 500;
  --vel-typography-headline-200-letter_spacing: normal;
  --vel-typography-headline-200-font_size: 20px;
  --vel-typography-headline-200-line_height: 1.2;
  --vel-typography-headline-300-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-300-font_weight: 500;
  --vel-typography-headline-300-letter_spacing: normal;
  --vel-typography-headline-300-font_size: 24px;
  --vel-typography-headline-300-line_height: 1.3333333333333333;
  --vel-typography-headline-400-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-400-font_weight: 500;
  --vel-typography-headline-400-letter_spacing: normal;
  --vel-typography-headline-400-font_size: 28px;
  --vel-typography-headline-400-line_height: 1.2857142857142858;
  --vel-typography-headline-500-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-500-font_weight: 500;
  --vel-typography-headline-500-letter_spacing: normal;
  --vel-typography-headline-500-font_size: 32px;
  --vel-typography-headline-500-line_height: 1.25;
  --vel-typography-headline-600-font_family: 'Chillax', sans-serif;
  --vel-typography-headline-600-font_weight: 500;
  --vel-typography-headline-600-letter_spacing: normal;
  --vel-typography-headline-600-font_size: 40px;
  --vel-typography-headline-600-line_height: 1.2;
}
@media only screen and (min-width: 740px) {
  :root {
    --vel-typography-headline-100-font_size: 16px;
    --vel-typography-headline-100-line_height: 1.25;
    --vel-typography-headline-200-font_size: 20px;
    --vel-typography-headline-200-line_height: 1.2;
    --vel-typography-headline-300-font_size: 24px;
    --vel-typography-headline-300-line_height: 1.3333333333333333;
    --vel-typography-headline-400-font_size: 32px;
    --vel-typography-headline-400-line_height: 1.25;
    --vel-typography-headline-500-font_size: 44px;
    --vel-typography-headline-500-line_height: 1.2727272727272727;
    --vel-typography-headline-600-font_size: 56px;
    --vel-typography-headline-600-line_height: 1.2857142857142858;
  }
}
@media only screen and (min-width: 1278px) {
  :root {
    --vel-typography-headline-100-font_size: 16px;
    --vel-typography-headline-100-line_height: 1.5;
    --vel-typography-headline-200-font_size: 20px;
    --vel-typography-headline-200-line_height: 1.4;
    --vel-typography-headline-300-font_size: 28px;
    --vel-typography-headline-300-line_height: 1.2857142857142858;
    --vel-typography-headline-400-font_size: 40px;
    --vel-typography-headline-400-line_height: 1.2;
    --vel-typography-headline-500-font_size: 52px;
    --vel-typography-headline-500-line_height: 1.2307692307692308;
    --vel-typography-headline-600-font_size: 68px;
    --vel-typography-headline-600-line_height: 1.1764705882352942;
  }
}
:root {
  --vel-animation-timing-function: ease;
  --vel-animation-timing-small: 100ms;
  --vel-animation-timing-medium: 200ms;
  --vel-animation-timing-large: 300ms;
}

@font-face {
  font-family: 'LeasePlan';
  font-style: normal;
  font-weight: 300;
  src: local('LeasePlan'),
    url(https://fonts.leaseplancdn.com/v1/LeasePlan-Light.woff2) format('woff2'),
    url(https://fonts.leaseplancdn.com/v1/LeasePlan-Light.woff) format('woff');
}
@font-face {
  font-family: 'LeasePlan';
  font-style: normal;
  font-weight: normal;
  src: local('LeasePlan'),
    url(https://fonts.leaseplancdn.com/v1/LeasePlan-Regular.woff2)
      format('woff2'),
    url(https://fonts.leaseplancdn.com/v1/LeasePlan-Regular.woff) format('woff');
}
@font-face {
  font-family: 'LeasePlan';
  font-style: normal;
  font-weight: bold;
  src: local('LeasePlan'),
    url(https://fonts.leaseplancdn.com/v1/LeasePlan-Bold.woff2) format('woff2'),
    url(https://fonts.leaseplancdn.com/v1/LeasePlan-Bold.woff) format('woff');
}

body {
  font-weight: normal;
  font-weight: var(--vel-typography-text-300-font_weight);
  font-size: 16px;
  font-size: var(--vel-typography-text-300-font_size);
  line-height: 1.5;
  line-height: var(--vel-typography-text-300-line_height);
  font-family: LeasePlan, Arial, Helvetica, sans-serif;
  font-family: var(--vel-typography-text-300-font_family);
  letter-spacing: normal;
  letter-spacing: var(--vel-typography-text-300-letter_spacing);
  color: rgb(45, 45, 45);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
:root {
  --vel-zindex-dropdown: 10;
  --vel-zindex-top_navigation: 1000;
  --vel-zindex-tooltip: 1100;
  --vel-zindex-drawer: 1200;
  --vel-zindex-dropdown-inside-drawer: 1210;
  --vel-zindex-modal: 1300;
  --vel-zindex-dropdown-inside-modal: 1310;
  --vel-zindex-toaster: 1400;
  --vel-zindex-grid_util: 10000;
}
:root {
  --vel-breakpoint-xs: 0px;
  --vel-breakpoint-sm: 480px;
  --vel-breakpoint-md: 740px;
  --vel-breakpoint-lg: 960px;
  --vel-breakpoint-xl: 1278px;
  --vel-breakpoint-xxl: 1600px;
}
:root {
  --vel-fixed-spacing-01: 2px;
  --vel-fixed-spacing-02: 4px;
  --vel-fixed-spacing-03: 8px;
  --vel-fixed-spacing-04: 12px;
  --vel-fixed-spacing-05: 16px;
  --vel-fixed-spacing-06: 20px;
  --vel-fixed-spacing-07: 24px;
  --vel-fixed-spacing-08: 32px;
  --vel-fixed-spacing-09: 40px;
  --vel-layout-spacing-01: 4px;
  --vel-layout-spacing-02: 8px;
  --vel-layout-spacing-03: 12px;
  --vel-layout-spacing-04: 16px;
  --vel-layout-spacing-05: 24px;
  --vel-layout-spacing-06: 32px;
  --vel-layout-spacing-07: 48px;
  --vel-layout-spacing-08: 64px;
}
@media only screen and (min-width: 740px) {
  :root {
    --vel-layout-spacing-01: 4px;
    --vel-layout-spacing-02: 8px;
    --vel-layout-spacing-03: 16px;
    --vel-layout-spacing-04: 24px;
    --vel-layout-spacing-05: 32px;
    --vel-layout-spacing-06: 48px;
    --vel-layout-spacing-07: 64px;
    --vel-layout-spacing-08: 96px;
  }
}
.vel-margin-top-01.vel-margin-top-01 {
  -webkit-margin-before: 2px;
          margin-block-start: 2px;
  -webkit-margin-before: var(--vel-fixed-spacing-01);
          margin-block-start: var(--vel-fixed-spacing-01);
}
.vel-margin-bottom-01.vel-margin-bottom-01 {
  -webkit-margin-after: 2px;
          margin-block-end: 2px;
  -webkit-margin-after: var(--vel-fixed-spacing-01);
          margin-block-end: var(--vel-fixed-spacing-01);
}
.vel-margin-y-01.vel-margin-y-01 {
  margin-block: 2px;
  margin-block: var(--vel-fixed-spacing-01);
}
.vel-margin-start-01.vel-margin-start-01 {
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-start: var(--vel-fixed-spacing-01);
          margin-inline-start: var(--vel-fixed-spacing-01);
}
.vel-margin-end-01.vel-margin-end-01 {
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
  -webkit-margin-end: var(--vel-fixed-spacing-01);
          margin-inline-end: var(--vel-fixed-spacing-01);
}
.vel-margin-x-01.vel-margin-x-01 {
  margin-inline: 2px;
  margin-inline: var(--vel-fixed-spacing-01);
}
.vel-margin-around-01.vel-margin-around-01 {
  margin: 2px;
  margin: var(--vel-fixed-spacing-01);
}
.vel-margin-top-02.vel-margin-top-02 {
  -webkit-margin-before: 4px;
          margin-block-start: 4px;
  -webkit-margin-before: var(--vel-fixed-spacing-02);
          margin-block-start: var(--vel-fixed-spacing-02);
}
.vel-margin-bottom-02.vel-margin-bottom-02 {
  -webkit-margin-after: 4px;
          margin-block-end: 4px;
  -webkit-margin-after: var(--vel-fixed-spacing-02);
          margin-block-end: var(--vel-fixed-spacing-02);
}
.vel-margin-y-02.vel-margin-y-02 {
  margin-block: 4px;
  margin-block: var(--vel-fixed-spacing-02);
}
.vel-margin-start-02.vel-margin-start-02 {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-start: var(--vel-fixed-spacing-02);
          margin-inline-start: var(--vel-fixed-spacing-02);
}
.vel-margin-end-02.vel-margin-end-02 {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-margin-end: var(--vel-fixed-spacing-02);
          margin-inline-end: var(--vel-fixed-spacing-02);
}
.vel-margin-x-02.vel-margin-x-02 {
  margin-inline: 4px;
  margin-inline: var(--vel-fixed-spacing-02);
}
.vel-margin-around-02.vel-margin-around-02 {
  margin: 4px;
  margin: var(--vel-fixed-spacing-02);
}
.vel-margin-top-03.vel-margin-top-03 {
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
  -webkit-margin-before: var(--vel-fixed-spacing-03);
          margin-block-start: var(--vel-fixed-spacing-03);
}
.vel-margin-bottom-03.vel-margin-bottom-03 {
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
  -webkit-margin-after: var(--vel-fixed-spacing-03);
          margin-block-end: var(--vel-fixed-spacing-03);
}
.vel-margin-y-03.vel-margin-y-03 {
  margin-block: 8px;
  margin-block: var(--vel-fixed-spacing-03);
}
.vel-margin-start-03.vel-margin-start-03 {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-start: var(--vel-fixed-spacing-03);
          margin-inline-start: var(--vel-fixed-spacing-03);
}
.vel-margin-end-03.vel-margin-end-03 {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
  -webkit-margin-end: var(--vel-fixed-spacing-03);
          margin-inline-end: var(--vel-fixed-spacing-03);
}
.vel-margin-x-03.vel-margin-x-03 {
  margin-inline: 8px;
  margin-inline: var(--vel-fixed-spacing-03);
}
.vel-margin-around-03.vel-margin-around-03 {
  margin: 8px;
  margin: var(--vel-fixed-spacing-03);
}
.vel-margin-top-04.vel-margin-top-04 {
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-before: var(--vel-fixed-spacing-04);
          margin-block-start: var(--vel-fixed-spacing-04);
}
.vel-margin-bottom-04.vel-margin-bottom-04 {
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
  -webkit-margin-after: var(--vel-fixed-spacing-04);
          margin-block-end: var(--vel-fixed-spacing-04);
}
.vel-margin-y-04.vel-margin-y-04 {
  margin-block: 12px;
  margin-block: var(--vel-fixed-spacing-04);
}
.vel-margin-start-04.vel-margin-start-04 {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-start: var(--vel-fixed-spacing-04);
          margin-inline-start: var(--vel-fixed-spacing-04);
}
.vel-margin-end-04.vel-margin-end-04 {
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
  -webkit-margin-end: var(--vel-fixed-spacing-04);
          margin-inline-end: var(--vel-fixed-spacing-04);
}
.vel-margin-x-04.vel-margin-x-04 {
  margin-inline: 12px;
  margin-inline: var(--vel-fixed-spacing-04);
}
.vel-margin-around-04.vel-margin-around-04 {
  margin: 12px;
  margin: var(--vel-fixed-spacing-04);
}
.vel-margin-top-05.vel-margin-top-05 {
  -webkit-margin-before: 16px;
          margin-block-start: 16px;
  -webkit-margin-before: var(--vel-fixed-spacing-05);
          margin-block-start: var(--vel-fixed-spacing-05);
}
.vel-margin-bottom-05.vel-margin-bottom-05 {
  -webkit-margin-after: 16px;
          margin-block-end: 16px;
  -webkit-margin-after: var(--vel-fixed-spacing-05);
          margin-block-end: var(--vel-fixed-spacing-05);
}
.vel-margin-y-05.vel-margin-y-05 {
  margin-block: 16px;
  margin-block: var(--vel-fixed-spacing-05);
}
.vel-margin-start-05.vel-margin-start-05 {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-start: var(--vel-fixed-spacing-05);
          margin-inline-start: var(--vel-fixed-spacing-05);
}
.vel-margin-end-05.vel-margin-end-05 {
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
  -webkit-margin-end: var(--vel-fixed-spacing-05);
          margin-inline-end: var(--vel-fixed-spacing-05);
}
.vel-margin-x-05.vel-margin-x-05 {
  margin-inline: 16px;
  margin-inline: var(--vel-fixed-spacing-05);
}
.vel-margin-around-05.vel-margin-around-05 {
  margin: 16px;
  margin: var(--vel-fixed-spacing-05);
}
.vel-margin-top-06.vel-margin-top-06 {
  -webkit-margin-before: 20px;
          margin-block-start: 20px;
  -webkit-margin-before: var(--vel-fixed-spacing-06);
          margin-block-start: var(--vel-fixed-spacing-06);
}
.vel-margin-bottom-06.vel-margin-bottom-06 {
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
  -webkit-margin-after: var(--vel-fixed-spacing-06);
          margin-block-end: var(--vel-fixed-spacing-06);
}
.vel-margin-y-06.vel-margin-y-06 {
  margin-block: 20px;
  margin-block: var(--vel-fixed-spacing-06);
}
.vel-margin-start-06.vel-margin-start-06 {
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
  -webkit-margin-start: var(--vel-fixed-spacing-06);
          margin-inline-start: var(--vel-fixed-spacing-06);
}
.vel-margin-end-06.vel-margin-end-06 {
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
  -webkit-margin-end: var(--vel-fixed-spacing-06);
          margin-inline-end: var(--vel-fixed-spacing-06);
}
.vel-margin-x-06.vel-margin-x-06 {
  margin-inline: 20px;
  margin-inline: var(--vel-fixed-spacing-06);
}
.vel-margin-around-06.vel-margin-around-06 {
  margin: 20px;
  margin: var(--vel-fixed-spacing-06);
}
.vel-margin-top-07.vel-margin-top-07 {
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  -webkit-margin-before: var(--vel-fixed-spacing-07);
          margin-block-start: var(--vel-fixed-spacing-07);
}
.vel-margin-bottom-07.vel-margin-bottom-07 {
  -webkit-margin-after: 24px;
          margin-block-end: 24px;
  -webkit-margin-after: var(--vel-fixed-spacing-07);
          margin-block-end: var(--vel-fixed-spacing-07);
}
.vel-margin-y-07.vel-margin-y-07 {
  margin-block: 24px;
  margin-block: var(--vel-fixed-spacing-07);
}
.vel-margin-start-07.vel-margin-start-07 {
  -webkit-margin-start: 24px;
          margin-inline-start: 24px;
  -webkit-margin-start: var(--vel-fixed-spacing-07);
          margin-inline-start: var(--vel-fixed-spacing-07);
}
.vel-margin-end-07.vel-margin-end-07 {
  -webkit-margin-end: 24px;
          margin-inline-end: 24px;
  -webkit-margin-end: var(--vel-fixed-spacing-07);
          margin-inline-end: var(--vel-fixed-spacing-07);
}
.vel-margin-x-07.vel-margin-x-07 {
  margin-inline: 24px;
  margin-inline: var(--vel-fixed-spacing-07);
}
.vel-margin-around-07.vel-margin-around-07 {
  margin: 24px;
  margin: var(--vel-fixed-spacing-07);
}
.vel-margin-top-08.vel-margin-top-08 {
  -webkit-margin-before: 32px;
          margin-block-start: 32px;
  -webkit-margin-before: var(--vel-fixed-spacing-08);
          margin-block-start: var(--vel-fixed-spacing-08);
}
.vel-margin-bottom-08.vel-margin-bottom-08 {
  -webkit-margin-after: 32px;
          margin-block-end: 32px;
  -webkit-margin-after: var(--vel-fixed-spacing-08);
          margin-block-end: var(--vel-fixed-spacing-08);
}
.vel-margin-y-08.vel-margin-y-08 {
  margin-block: 32px;
  margin-block: var(--vel-fixed-spacing-08);
}
.vel-margin-start-08.vel-margin-start-08 {
  -webkit-margin-start: 32px;
          margin-inline-start: 32px;
  -webkit-margin-start: var(--vel-fixed-spacing-08);
          margin-inline-start: var(--vel-fixed-spacing-08);
}
.vel-margin-end-08.vel-margin-end-08 {
  -webkit-margin-end: 32px;
          margin-inline-end: 32px;
  -webkit-margin-end: var(--vel-fixed-spacing-08);
          margin-inline-end: var(--vel-fixed-spacing-08);
}
.vel-margin-x-08.vel-margin-x-08 {
  margin-inline: 32px;
  margin-inline: var(--vel-fixed-spacing-08);
}
.vel-margin-around-08.vel-margin-around-08 {
  margin: 32px;
  margin: var(--vel-fixed-spacing-08);
}
.vel-margin-top-09.vel-margin-top-09 {
  -webkit-margin-before: 40px;
          margin-block-start: 40px;
  -webkit-margin-before: var(--vel-fixed-spacing-09);
          margin-block-start: var(--vel-fixed-spacing-09);
}
.vel-margin-bottom-09.vel-margin-bottom-09 {
  -webkit-margin-after: 40px;
          margin-block-end: 40px;
  -webkit-margin-after: var(--vel-fixed-spacing-09);
          margin-block-end: var(--vel-fixed-spacing-09);
}
.vel-margin-y-09.vel-margin-y-09 {
  margin-block: 40px;
  margin-block: var(--vel-fixed-spacing-09);
}
.vel-margin-start-09.vel-margin-start-09 {
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  -webkit-margin-start: var(--vel-fixed-spacing-09);
          margin-inline-start: var(--vel-fixed-spacing-09);
}
.vel-margin-end-09.vel-margin-end-09 {
  -webkit-margin-end: 40px;
          margin-inline-end: 40px;
  -webkit-margin-end: var(--vel-fixed-spacing-09);
          margin-inline-end: var(--vel-fixed-spacing-09);
}
.vel-margin-x-09.vel-margin-x-09 {
  margin-inline: 40px;
  margin-inline: var(--vel-fixed-spacing-09);
}
.vel-margin-around-09.vel-margin-around-09 {
  margin: 40px;
  margin: var(--vel-fixed-spacing-09);
}
.vel-layout-margin-top-01.vel-layout-margin-top-01 {
  -webkit-margin-before: 4px;
          margin-block-start: 4px;
  -webkit-margin-before: var(--vel-layout-spacing-01);
          margin-block-start: var(--vel-layout-spacing-01);
}
.vel-layout-margin-bottom-01.vel-layout-margin-bottom-01 {
  -webkit-margin-after: 4px;
          margin-block-end: 4px;
  -webkit-margin-after: var(--vel-layout-spacing-01);
          margin-block-end: var(--vel-layout-spacing-01);
}
.vel-layout-margin-y-01.vel-layout-margin-y-01 {
  margin-block: 4px;
  margin-block: var(--vel-layout-spacing-01);
}
.vel-layout-margin-start-01.vel-layout-margin-start-01 {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-start: var(--vel-layout-spacing-01);
          margin-inline-start: var(--vel-layout-spacing-01);
}
.vel-layout-margin-end-01.vel-layout-margin-end-01 {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-margin-end: var(--vel-layout-spacing-01);
          margin-inline-end: var(--vel-layout-spacing-01);
}
.vel-layout-margin-x-01.vel-layout-margin-x-01 {
  margin-inline: 4px;
  margin-inline: var(--vel-layout-spacing-01);
}
.vel-layout-margin-around-01.vel-layout-margin-around-01 {
  margin: 4px;
  margin: var(--vel-layout-spacing-01);
}
.vel-layout-margin-top-02.vel-layout-margin-top-02 {
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
  -webkit-margin-before: var(--vel-layout-spacing-02);
          margin-block-start: var(--vel-layout-spacing-02);
}
.vel-layout-margin-bottom-02.vel-layout-margin-bottom-02 {
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
  -webkit-margin-after: var(--vel-layout-spacing-02);
          margin-block-end: var(--vel-layout-spacing-02);
}
.vel-layout-margin-y-02.vel-layout-margin-y-02 {
  margin-block: 8px;
  margin-block: var(--vel-layout-spacing-02);
}
.vel-layout-margin-start-02.vel-layout-margin-start-02 {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-start: var(--vel-layout-spacing-02);
          margin-inline-start: var(--vel-layout-spacing-02);
}
.vel-layout-margin-end-02.vel-layout-margin-end-02 {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
  -webkit-margin-end: var(--vel-layout-spacing-02);
          margin-inline-end: var(--vel-layout-spacing-02);
}
.vel-layout-margin-x-02.vel-layout-margin-x-02 {
  margin-inline: 8px;
  margin-inline: var(--vel-layout-spacing-02);
}
.vel-layout-margin-around-02.vel-layout-margin-around-02 {
  margin: 8px;
  margin: var(--vel-layout-spacing-02);
}
.vel-layout-margin-top-03.vel-layout-margin-top-03 {
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-before: var(--vel-layout-spacing-03);
          margin-block-start: var(--vel-layout-spacing-03);
}
.vel-layout-margin-bottom-03.vel-layout-margin-bottom-03 {
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
  -webkit-margin-after: var(--vel-layout-spacing-03);
          margin-block-end: var(--vel-layout-spacing-03);
}
.vel-layout-margin-y-03.vel-layout-margin-y-03 {
  margin-block: 12px;
  margin-block: var(--vel-layout-spacing-03);
}
.vel-layout-margin-start-03.vel-layout-margin-start-03 {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-start: var(--vel-layout-spacing-03);
          margin-inline-start: var(--vel-layout-spacing-03);
}
.vel-layout-margin-end-03.vel-layout-margin-end-03 {
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
  -webkit-margin-end: var(--vel-layout-spacing-03);
          margin-inline-end: var(--vel-layout-spacing-03);
}
.vel-layout-margin-x-03.vel-layout-margin-x-03 {
  margin-inline: 12px;
  margin-inline: var(--vel-layout-spacing-03);
}
.vel-layout-margin-around-03.vel-layout-margin-around-03 {
  margin: 12px;
  margin: var(--vel-layout-spacing-03);
}
.vel-layout-margin-top-04.vel-layout-margin-top-04 {
  -webkit-margin-before: 16px;
          margin-block-start: 16px;
  -webkit-margin-before: var(--vel-layout-spacing-04);
          margin-block-start: var(--vel-layout-spacing-04);
}
.vel-layout-margin-bottom-04.vel-layout-margin-bottom-04 {
  -webkit-margin-after: 16px;
          margin-block-end: 16px;
  -webkit-margin-after: var(--vel-layout-spacing-04);
          margin-block-end: var(--vel-layout-spacing-04);
}
.vel-layout-margin-y-04.vel-layout-margin-y-04 {
  margin-block: 16px;
  margin-block: var(--vel-layout-spacing-04);
}
.vel-layout-margin-start-04.vel-layout-margin-start-04 {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-start: var(--vel-layout-spacing-04);
          margin-inline-start: var(--vel-layout-spacing-04);
}
.vel-layout-margin-end-04.vel-layout-margin-end-04 {
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
  -webkit-margin-end: var(--vel-layout-spacing-04);
          margin-inline-end: var(--vel-layout-spacing-04);
}
.vel-layout-margin-x-04.vel-layout-margin-x-04 {
  margin-inline: 16px;
  margin-inline: var(--vel-layout-spacing-04);
}
.vel-layout-margin-around-04.vel-layout-margin-around-04 {
  margin: 16px;
  margin: var(--vel-layout-spacing-04);
}
.vel-layout-margin-top-05.vel-layout-margin-top-05 {
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  -webkit-margin-before: var(--vel-layout-spacing-05);
          margin-block-start: var(--vel-layout-spacing-05);
}
.vel-layout-margin-bottom-05.vel-layout-margin-bottom-05 {
  -webkit-margin-after: 24px;
          margin-block-end: 24px;
  -webkit-margin-after: var(--vel-layout-spacing-05);
          margin-block-end: var(--vel-layout-spacing-05);
}
.vel-layout-margin-y-05.vel-layout-margin-y-05 {
  margin-block: 24px;
  margin-block: var(--vel-layout-spacing-05);
}
.vel-layout-margin-start-05.vel-layout-margin-start-05 {
  -webkit-margin-start: 24px;
          margin-inline-start: 24px;
  -webkit-margin-start: var(--vel-layout-spacing-05);
          margin-inline-start: var(--vel-layout-spacing-05);
}
.vel-layout-margin-end-05.vel-layout-margin-end-05 {
  -webkit-margin-end: 24px;
          margin-inline-end: 24px;
  -webkit-margin-end: var(--vel-layout-spacing-05);
          margin-inline-end: var(--vel-layout-spacing-05);
}
.vel-layout-margin-x-05.vel-layout-margin-x-05 {
  margin-inline: 24px;
  margin-inline: var(--vel-layout-spacing-05);
}
.vel-layout-margin-around-05.vel-layout-margin-around-05 {
  margin: 24px;
  margin: var(--vel-layout-spacing-05);
}
.vel-layout-margin-top-06.vel-layout-margin-top-06 {
  -webkit-margin-before: 32px;
          margin-block-start: 32px;
  -webkit-margin-before: var(--vel-layout-spacing-06);
          margin-block-start: var(--vel-layout-spacing-06);
}
.vel-layout-margin-bottom-06.vel-layout-margin-bottom-06 {
  -webkit-margin-after: 32px;
          margin-block-end: 32px;
  -webkit-margin-after: var(--vel-layout-spacing-06);
          margin-block-end: var(--vel-layout-spacing-06);
}
.vel-layout-margin-y-06.vel-layout-margin-y-06 {
  margin-block: 32px;
  margin-block: var(--vel-layout-spacing-06);
}
.vel-layout-margin-start-06.vel-layout-margin-start-06 {
  -webkit-margin-start: 32px;
          margin-inline-start: 32px;
  -webkit-margin-start: var(--vel-layout-spacing-06);
          margin-inline-start: var(--vel-layout-spacing-06);
}
.vel-layout-margin-end-06.vel-layout-margin-end-06 {
  -webkit-margin-end: 32px;
          margin-inline-end: 32px;
  -webkit-margin-end: var(--vel-layout-spacing-06);
          margin-inline-end: var(--vel-layout-spacing-06);
}
.vel-layout-margin-x-06.vel-layout-margin-x-06 {
  margin-inline: 32px;
  margin-inline: var(--vel-layout-spacing-06);
}
.vel-layout-margin-around-06.vel-layout-margin-around-06 {
  margin: 32px;
  margin: var(--vel-layout-spacing-06);
}
.vel-layout-margin-top-07.vel-layout-margin-top-07 {
  -webkit-margin-before: 48px;
          margin-block-start: 48px;
  -webkit-margin-before: var(--vel-layout-spacing-07);
          margin-block-start: var(--vel-layout-spacing-07);
}
.vel-layout-margin-bottom-07.vel-layout-margin-bottom-07 {
  -webkit-margin-after: 48px;
          margin-block-end: 48px;
  -webkit-margin-after: var(--vel-layout-spacing-07);
          margin-block-end: var(--vel-layout-spacing-07);
}
.vel-layout-margin-y-07.vel-layout-margin-y-07 {
  margin-block: 48px;
  margin-block: var(--vel-layout-spacing-07);
}
.vel-layout-margin-start-07.vel-layout-margin-start-07 {
  -webkit-margin-start: 48px;
          margin-inline-start: 48px;
  -webkit-margin-start: var(--vel-layout-spacing-07);
          margin-inline-start: var(--vel-layout-spacing-07);
}
.vel-layout-margin-end-07.vel-layout-margin-end-07 {
  -webkit-margin-end: 48px;
          margin-inline-end: 48px;
  -webkit-margin-end: var(--vel-layout-spacing-07);
          margin-inline-end: var(--vel-layout-spacing-07);
}
.vel-layout-margin-x-07.vel-layout-margin-x-07 {
  margin-inline: 48px;
  margin-inline: var(--vel-layout-spacing-07);
}
.vel-layout-margin-around-07.vel-layout-margin-around-07 {
  margin: 48px;
  margin: var(--vel-layout-spacing-07);
}
.vel-layout-margin-top-08.vel-layout-margin-top-08 {
  -webkit-margin-before: 64px;
          margin-block-start: 64px;
  -webkit-margin-before: var(--vel-layout-spacing-08);
          margin-block-start: var(--vel-layout-spacing-08);
}
.vel-layout-margin-bottom-08.vel-layout-margin-bottom-08 {
  -webkit-margin-after: 64px;
          margin-block-end: 64px;
  -webkit-margin-after: var(--vel-layout-spacing-08);
          margin-block-end: var(--vel-layout-spacing-08);
}
.vel-layout-margin-y-08.vel-layout-margin-y-08 {
  margin-block: 64px;
  margin-block: var(--vel-layout-spacing-08);
}
.vel-layout-margin-start-08.vel-layout-margin-start-08 {
  -webkit-margin-start: 64px;
          margin-inline-start: 64px;
  -webkit-margin-start: var(--vel-layout-spacing-08);
          margin-inline-start: var(--vel-layout-spacing-08);
}
.vel-layout-margin-end-08.vel-layout-margin-end-08 {
  -webkit-margin-end: 64px;
          margin-inline-end: 64px;
  -webkit-margin-end: var(--vel-layout-spacing-08);
          margin-inline-end: var(--vel-layout-spacing-08);
}
.vel-layout-margin-x-08.vel-layout-margin-x-08 {
  margin-inline: 64px;
  margin-inline: var(--vel-layout-spacing-08);
}
.vel-layout-margin-around-08.vel-layout-margin-around-08 {
  margin: 64px;
  margin: var(--vel-layout-spacing-08);
}
:root {
  --vel-color-primary-500-raw: 238, 76, 20;
  --vel-color-primary-400-raw: 240, 100, 0;
  --vel-color-primary-300-raw: 237, 139, 0;
  --vel-color-primary-200-raw: 243, 178, 87;
  --vel-color-primary-100-raw: 249, 214, 166;
  --vel-color-primary-50-raw: 253, 243, 229;
  --vel-color-secondary-500-raw: 0, 74, 93;
  --vel-color-secondary-400-raw: 51, 110, 125;
  --vel-color-secondary-300-raw: 102, 146, 158;
  --vel-color-secondary-200-raw: 153, 183, 190;
  --vel-color-secondary-100-raw: 204, 219, 223;
  --vel-color-secondary-50-raw: 231, 238, 241;
  --vel-color-brand-support1-500-raw: 0, 177, 175;
  --vel-color-brand-support1-400-raw: 51, 193, 191;
  --vel-color-brand-support1-300-raw: 102, 208, 207;
  --vel-color-brand-support1-200-raw: 140, 220, 219;
  --vel-color-brand-support1-100-raw: 178, 232, 231;
  --vel-color-brand-support1-50-raw: 217, 243, 243;
  --vel-color-brand-support2-500-raw: 0, 154, 23;
  --vel-color-brand-support2-400-raw: 51, 174, 69;
  --vel-color-brand-support2-300-raw: 102, 194, 116;
  --vel-color-brand-support2-200-raw: 140, 210, 151;
  --vel-color-brand-support2-100-raw: 178, 225, 185;
  --vel-color-brand-support2-50-raw: 217, 240, 220;
  --vel-color-brand-support3-500-raw: 169, 201, 14;
  --vel-color-brand-support3-400-raw: 186, 212, 62;
  --vel-color-brand-support3-300-raw: 203, 223, 110;
  --vel-color-brand-support3-200-raw: 216, 231, 147;
  --vel-color-brand-support3-100-raw: 229, 239, 183;
  --vel-color-brand-support3-50-raw: 242, 247, 219;
  --vel-color-neutral-900-raw: 0, 0, 0;
  --vel-color-neutral-800-raw: 45, 45, 45;
  --vel-color-neutral-700-raw: 84, 86, 86;
  --vel-color-neutral-600-raw: 118, 120, 120;
  --vel-color-neutral-500-raw: 152, 154, 154;
  --vel-color-neutral-400-raw: 187, 187, 187;
  --vel-color-neutral-300-raw: 221, 221, 221;
  --vel-color-neutral-200-raw: 238, 238, 238;
  --vel-color-neutral-100-raw: 246, 246, 246;
  --vel-color-neutral-white-raw: 255, 255, 255;
  --vel-color-background-main-raw: 246, 248, 251;
  --vel-color-background-alt-raw: 255, 255, 255;
  --vel-color-semantic-error-high-raw: 207, 53, 22;
  --vel-color-semantic-error-low-raw: 254, 244, 244;
  --vel-color-semantic-success-high-raw: 6, 116, 23;
  --vel-color-semantic-success-low-raw: 237, 248, 238;
  --vel-color-semantic-warning-high-raw: 212, 128, 0;
  --vel-color-semantic-warning-low-raw: 255, 249, 237;
  --vel-color-semantic-info-high-raw: 28, 104, 202;
  --vel-color-semantic-info-low-raw: 240, 246, 255;
  --vel-color-misc-focused-raw: 0, 101, 255;
  --vel-color-misc-pressed-raw: 69, 143, 255, 0.25;
  --vel-color-text-headlines-raw: 0, 74, 93;
  --vel-color-text-default-raw: 45, 45, 45;
  --vel-color-text-on_primary-raw: 255, 255, 255;
  --vel-color-text-on_secondary-raw: 255, 255, 255;
  --vel-color-text-on_brand_support1-raw: 255, 255, 255;
  --vel-color-text-on_brand_support2-raw: 255, 255, 255;
  --vel-color-text-on_brand_support3-raw: 255, 255, 255;
  --vel-color-primary-500: rgb(var(--vel-color-primary-500-raw));
  --vel-color-primary-400: rgb(var(--vel-color-primary-400-raw));
  --vel-color-primary-300: rgb(var(--vel-color-primary-300-raw));
  --vel-color-primary-200: rgb(var(--vel-color-primary-200-raw));
  --vel-color-primary-100: rgb(var(--vel-color-primary-100-raw));
  --vel-color-primary-50: rgb(var(--vel-color-primary-50-raw));
  --vel-color-secondary-500: rgb(var(--vel-color-secondary-500-raw));
  --vel-color-secondary-400: rgb(var(--vel-color-secondary-400-raw));
  --vel-color-secondary-300: rgb(var(--vel-color-secondary-300-raw));
  --vel-color-secondary-200: rgb(var(--vel-color-secondary-200-raw));
  --vel-color-secondary-100: rgb(var(--vel-color-secondary-100-raw));
  --vel-color-secondary-50: rgb(var(--vel-color-secondary-50-raw));
  --vel-color-brand-support1-500: rgb(var(--vel-color-brand-support1-500-raw));
  --vel-color-brand-support1-400: rgb(var(--vel-color-brand-support1-400-raw));
  --vel-color-brand-support1-300: rgb(var(--vel-color-brand-support1-300-raw));
  --vel-color-brand-support1-200: rgb(var(--vel-color-brand-support1-200-raw));
  --vel-color-brand-support1-100: rgb(var(--vel-color-brand-support1-100-raw));
  --vel-color-brand-support1-50: rgb(var(--vel-color-brand-support1-50-raw));
  --vel-color-brand-support2-500: rgb(var(--vel-color-brand-support2-500-raw));
  --vel-color-brand-support2-400: rgb(var(--vel-color-brand-support2-400-raw));
  --vel-color-brand-support2-300: rgb(var(--vel-color-brand-support2-300-raw));
  --vel-color-brand-support2-200: rgb(var(--vel-color-brand-support2-200-raw));
  --vel-color-brand-support2-100: rgb(var(--vel-color-brand-support2-100-raw));
  --vel-color-brand-support2-50: rgb(var(--vel-color-brand-support2-50-raw));
  --vel-color-brand-support3-500: rgb(var(--vel-color-brand-support3-500-raw));
  --vel-color-brand-support3-400: rgb(var(--vel-color-brand-support3-400-raw));
  --vel-color-brand-support3-300: rgb(var(--vel-color-brand-support3-300-raw));
  --vel-color-brand-support3-200: rgb(var(--vel-color-brand-support3-200-raw));
  --vel-color-brand-support3-100: rgb(var(--vel-color-brand-support3-100-raw));
  --vel-color-brand-support3-50: rgb(var(--vel-color-brand-support3-50-raw));
  --vel-color-neutral-900: rgb(var(--vel-color-neutral-900-raw));
  --vel-color-neutral-800: rgb(var(--vel-color-neutral-800-raw));
  --vel-color-neutral-700: rgb(var(--vel-color-neutral-700-raw));
  --vel-color-neutral-600: rgb(var(--vel-color-neutral-600-raw));
  --vel-color-neutral-500: rgb(var(--vel-color-neutral-500-raw));
  --vel-color-neutral-400: rgb(var(--vel-color-neutral-400-raw));
  --vel-color-neutral-300: rgb(var(--vel-color-neutral-300-raw));
  --vel-color-neutral-200: rgb(var(--vel-color-neutral-200-raw));
  --vel-color-neutral-100: rgb(var(--vel-color-neutral-100-raw));
  --vel-color-neutral-white: rgb(var(--vel-color-neutral-white-raw));
  --vel-color-background-main: rgb(var(--vel-color-background-main-raw));
  --vel-color-background-alt: rgb(var(--vel-color-background-alt-raw));
  --vel-color-semantic-error-high: rgb(
    var(--vel-color-semantic-error-high-raw)
  );
  --vel-color-semantic-error-low: rgb(var(--vel-color-semantic-error-low-raw));
  --vel-color-semantic-success-high: rgb(
    var(--vel-color-semantic-success-high-raw)
  );
  --vel-color-semantic-success-low: rgb(
    var(--vel-color-semantic-success-low-raw)
  );
  --vel-color-semantic-warning-high: rgb(
    var(--vel-color-semantic-warning-high-raw)
  );
  --vel-color-semantic-warning-low: rgb(
    var(--vel-color-semantic-warning-low-raw)
  );
  --vel-color-semantic-info-high: rgb(var(--vel-color-semantic-info-high-raw));
  --vel-color-semantic-info-low: rgb(var(--vel-color-semantic-info-low-raw));
  --vel-color-misc-focused: rgb(var(--vel-color-misc-focused-raw));
  --vel-color-misc-pressed: rgba(var(--vel-color-misc-pressed-raw));
  --vel-color-text-headlines: rgb(var(--vel-color-text-headlines-raw));
  --vel-color-text-default: rgb(var(--vel-color-text-default-raw));
  --vel-color-text-on_primary: rgb(var(--vel-color-text-on_primary-raw));
  --vel-color-text-on_secondary: rgb(var(--vel-color-text-on_secondary-raw));
  --vel-color-text-on_brand_support1: rgb(
    var(--vel-color-text-on_brand_support1-raw)
  );
  --vel-color-text-on_brand_support2: rgb(
    var(--vel-color-text-on_brand_support2-raw)
  );
  --vel-color-text-on_brand_support3: rgb(
    var(--vel-color-text-on_brand_support3-raw)
  );
  --vel-color-form-text-placeholder: var(--vel-color-neutral-500);
  --vel-color-form-text-disabled: var(--vel-color-neutral-500);
  --vel-color-form-bg-default: var(--vel-color-neutral-white);
  --vel-color-form-bg-disabled: var(--vel-color-neutral-200);
  --vel-color-form-border-default: var(--vel-color-neutral-500);
  --vel-color-form-border-hover: var(--vel-color-neutral-600);
  --vel-color-form-border-disabled: var(--vel-color-neutral-300);
  --vel-color-dividerColor: var(--vel-color-neutral-300);
}
:root {
  --vel-shape100-border_radius: 0px;
  --vel-shape200-border_radius: 2px;
  --vel-shape300-border_radius: 4px;
  --vel-shape400-border_radius: 12px;
  --vel-shape500-border_radius: 999px;
}
.vel-shape100.vel-shape100 {
  border-radius: 0px;
  border-radius: var(--vel-shape100-border_radius);
}
.vel-shape200.vel-shape200 {
  border-radius: 2px;
  border-radius: var(--vel-shape200-border_radius);
}
.vel-shape300.vel-shape300 {
  border-radius: 4px;
  border-radius: var(--vel-shape300-border_radius);
}
.vel-shape400.vel-shape400 {
  border-radius: 12px;
  border-radius: var(--vel-shape400-border_radius);
}
.vel-shape500.vel-shape500 {
  border-radius: 999px;
  border-radius: var(--vel-shape500-border_radius);
}
:root {
  --vel-elevation100-box_shadow: 0px 2px 2px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation200-box_shadow: 0px 4px 8px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation300-box_shadow: 0px 8px 12px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation400-box_shadow: 0px 12px 16px rgba(9, 30, 66, 0.12),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  --vel-elevation500-box_shadow: 0px 16px 32px rgba(9, 30, 66, 0.16),
    0px 0px 1px rgba(9, 30, 66, 0.2);
}
.vel-elevation100.vel-elevation100 {
  box-shadow: 0px 2px 2px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation100-box_shadow);
}
.vel-elevation200.vel-elevation200 {
  box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation200-box_shadow);
}
.vel-elevation300.vel-elevation300 {
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.08),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation300-box_shadow);
}
.vel-elevation400.vel-elevation400 {
  box-shadow: 0px 12px 16px rgba(9, 30, 66, 0.12),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation400-box_shadow);
}
.vel-elevation500.vel-elevation500 {
  box-shadow: 0px 16px 32px rgba(9, 30, 66, 0.16),
    0px 0px 1px rgba(9, 30, 66, 0.2);
  box-shadow: var(--vel-elevation500-box_shadow);
}
:root {
  --vel-elevation100-filter: drop-shadow(0px 2px 2px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation200-filter: drop-shadow(0px 4px 8px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation300-filter: drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.08))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation400-filter: drop-shadow(0px 12px 16px rgba(9, 30, 66, 0.12))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
  --vel-elevation500-filter: drop-shadow(0px 16px 32px rgba(9, 30, 66, 0.16))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.2));
}
:root {
  --vel-typography-text-50-font_family: LeasePlan, Arial, Helvetica, sans-serif;
  --vel-typography-text-50-font_weight: normal;
  --vel-typography-text-50-letter_spacing: normal;
  --vel-typography-text-50-font_size: 10px;
  --vel-typography-text-50-line_height: 1.6;
  --vel-typography-text-50-bold-font_weight: bold;
  --vel-typography-text-50-loose-line_height: 2;
  --vel-typography-text-100-font_family: LeasePlan, Arial, Helvetica, sans-serif;
  --vel-typography-text-100-font_weight: normal;
  --vel-typography-text-100-letter_spacing: normal;
  --vel-typography-text-100-font_size: 12px;
  --vel-typography-text-100-line_height: 1.6666666666666667;
  --vel-typography-text-100-bold-font_weight: bold;
  --vel-typography-text-100-loose-line_height: 2;
  --vel-typography-text-200-font_family: LeasePlan, Arial, Helvetica, sans-serif;
  --vel-typography-text-200-font_weight: normal;
  --vel-typography-text-200-letter_spacing: normal;
  --vel-typography-text-200-font_size: 14px;
  --vel-typography-text-200-line_height: 1.4285714285714286;
  --vel-typography-text-200-bold-font_weight: bold;
  --vel-typography-text-200-loose-line_height: 1.7142857142857142;
  --vel-typography-text-300-font_family: LeasePlan, Arial, Helvetica, sans-serif;
  --vel-typography-text-300-font_weight: normal;
  --vel-typography-text-300-letter_spacing: normal;
  --vel-typography-text-300-font_size: 16px;
  --vel-typography-text-300-line_height: 1.5;
  --vel-typography-text-300-bold-font_weight: bold;
  --vel-typography-text-300-loose-line_height: 1.75;
  --vel-typography-text-400-font_family: LeasePlan, Arial, Helvetica, sans-serif;
  --vel-typography-text-400-font_weight: normal;
  --vel-typography-text-400-letter_spacing: normal;
  --vel-typography-text-400-font_size: 20px;
  --vel-typography-text-400-line_height: 1.4;
  --vel-typography-text-400-bold-font_weight: bold;
  --vel-typography-text-400-loose-line_height: 1.8;
  --vel-typography-text-500-font_family: LeasePlan, Arial, Helvetica, sans-serif;
  --vel-typography-text-500-font_weight: normal;
  --vel-typography-text-500-letter_spacing: normal;
  --vel-typography-text-500-font_size: 24px;
  --vel-typography-text-500-line_height: 1.5;
  --vel-typography-text-500-bold-font_weight: bold;
  --vel-typography-text-500-loose-line_height: 1.8333333333333333;
  --vel-typography-headline-100-font_family: LeasePlan, Arial, Helvetica,
    sans-serif;
  --vel-typography-headline-100-font_weight: bold;
  --vel-typography-headline-100-letter_spacing: normal;
  --vel-typography-headline-100-font_size: 16px;
  --vel-typography-headline-100-line_height: 1.25;
  --vel-typography-headline-200-font_family: LeasePlan, Arial, Helvetica,
    sans-serif;
  --vel-typography-headline-200-font_weight: bold;
  --vel-typography-headline-200-letter_spacing: normal;
  --vel-typography-headline-200-font_size: 20px;
  --vel-typography-headline-200-line_height: 1.2;
  --vel-typography-headline-300-font_family: LeasePlan, Arial, Helvetica,
    sans-serif;
  --vel-typography-headline-300-font_weight: normal;
  --vel-typography-headline-300-letter_spacing: normal;
  --vel-typography-headline-300-font_size: 24px;
  --vel-typography-headline-300-line_height: 1.3333333333333333;
  --vel-typography-headline-400-font_family: LeasePlan, Arial, Helvetica,
    sans-serif;
  --vel-typography-headline-400-font_weight: normal;
  --vel-typography-headline-400-letter_spacing: normal;
  --vel-typography-headline-400-font_size: 28px;
  --vel-typography-headline-400-line_height: 1.2857142857142858;
  --vel-typography-headline-500-font_family: LeasePlan, Arial, Helvetica,
    sans-serif;
  --vel-typography-headline-500-font_weight: normal;
  --vel-typography-headline-500-letter_spacing: normal;
  --vel-typography-headline-500-font_size: 32px;
  --vel-typography-headline-500-line_height: 1.25;
  --vel-typography-headline-600-font_family: LeasePlan, Arial, Helvetica,
    sans-serif;
  --vel-typography-headline-600-font_weight: normal;
  --vel-typography-headline-600-letter_spacing: normal;
  --vel-typography-headline-600-font_size: 40px;
  --vel-typography-headline-600-line_height: 1.2;
}
@media only screen and (min-width: 740px) {
  :root {
    --vel-typography-headline-100-font_weight: bold;
    --vel-typography-headline-100-font_size: 16px;
    --vel-typography-headline-100-line_height: 1.25;
    --vel-typography-headline-200-font_weight: bold;
    --vel-typography-headline-200-font_size: 20px;
    --vel-typography-headline-200-line_height: 1.2;
    --vel-typography-headline-300-font_weight: normal;
    --vel-typography-headline-300-font_size: 24px;
    --vel-typography-headline-300-line_height: 1.3333333333333333;
    --vel-typography-headline-400-font_weight: normal;
    --vel-typography-headline-400-font_size: 32px;
    --vel-typography-headline-400-line_height: 1.25;
    --vel-typography-headline-500-font_weight: normal;
    --vel-typography-headline-500-font_size: 44px;
    --vel-typography-headline-500-line_height: 1.2727272727272727;
    --vel-typography-headline-600-font_weight: 300;
    --vel-typography-headline-600-font_size: 56px;
    --vel-typography-headline-600-line_height: 1.2857142857142858;
  }
}
@media only screen and (min-width: 1278px) {
  :root {
    --vel-typography-headline-100-font_weight: bold;
    --vel-typography-headline-100-font_size: 16px;
    --vel-typography-headline-100-line_height: 1.5;
    --vel-typography-headline-200-font_weight: bold;
    --vel-typography-headline-200-font_size: 20px;
    --vel-typography-headline-200-line_height: 1.4;
    --vel-typography-headline-300-font_weight: normal;
    --vel-typography-headline-300-font_size: 28px;
    --vel-typography-headline-300-line_height: 1.2857142857142858;
    --vel-typography-headline-400-font_weight: normal;
    --vel-typography-headline-400-font_size: 40px;
    --vel-typography-headline-400-line_height: 1.2;
    --vel-typography-headline-500-font_weight: 300;
    --vel-typography-headline-500-font_size: 52px;
    --vel-typography-headline-500-line_height: 1.2307692307692308;
    --vel-typography-headline-600-font_weight: 300;
    --vel-typography-headline-600-font_size: 68px;
    --vel-typography-headline-600-line_height: 1.1764705882352942;
  }
}
:root {
  --vel-animation-timing-function: ease;
  --vel-animation-timing-small: 100ms;
  --vel-animation-timing-medium: 200ms;
  --vel-animation-timing-large: 300ms;
}

